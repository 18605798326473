<template>
  <v-menu offset-y content-class="elevation-1" height="3rem">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" class="px-0 my-0 language" outlined v-on="on">
        <BaseCountry :country="currentLang.flag" no-tooltip/>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="lang in langs"
        :key="lang.code"
        :class="lang.code == currentLang.code ? 'active-lang' : 'not-active-lang'"
        link
        @click="changeLang(lang)"
      >
        <v-list-item-title>
          <BaseCountry :country="lang.flag" no-tooltip/>
          <span class="pl-4" v-text="$t(`languages.${lang.code}`, lang.code === 'bypass' ? 'en' : lang.code)"/>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { VUE_APP_ENVIRONMENT } from "@/config"

import Vue from 'vue'

export default Vue.extend({
  name: 'BaseLocale',
  computed: {
    user: function() {
      return this.$auth.user()
    },
    langs() {
      const langs = [
        { code: 'en', flag: 'GB' },
        { code: 'de', flag: 'DE' },
        { code: 'et', flag: 'EE' },
        { code: 'ru', flag: 'RU' },
        { code: 'es', flag: 'ES' },
        { code: 'fr', flag: 'FR' },
      ]
      return VUE_APP_ENVIRONMENT === 'development' || VUE_APP_ENVIRONMENT === 'testing'
        ? [ ...langs, { code: 'bypass', flag: 'AQ' } ]
        : langs
    },
    currentLang() {
      return this.langs.find(lang => lang.code == this.$i18n.locale) || { 'flag': 'eu' }
    },
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang.code
      if (lang.code === 'bypass') {
        return
      }
      localStorage.setItem('locale', this.$i18n.locale)
      this.$vuetify.lang.current = this.$i18n.locale
      if (this.$auth.user()) {
        this.$http.patch(`/auth/users/me/`, { language: this.$i18n.locale })
      }
    },
  },
})
</script>
